import request from '../utils/request'


export function index(params) {
  return request({
    url: '/branches',
    method: 'get',
    params,
    timeout: 300000
  })
}

export function inventorywithWarehouse(params) {
  return request({
    url: '/branches/inventorywithWarehouse',
    method: 'get',
    params,
    timeout: 300000
  })
}
export function inventory(params) {
  return request({
    url: '/branches/inventory',
    method: 'get',
    params,
    timeout: 300000
  })
}

export function getLastId(params) {
  return request({
    url: '/branches/getLastId',
    method: 'get',
    params
  })
} 
export function show(id) {
    return request({
      url: `/branches/${id}`,
      method: 'get',
      timeout: 300000
    })
  }

  export function store(data) {
    return request({
      url: '/branches',
      method: 'post',
      data,
      timeout: 300000
    })
  }

  export function update(data) {
    return request({
      url: `/branches/${data.id}`,
      method: 'put',
      data,
      timeout: 300000
    })
  }

  export function destroy(id) {
    return request({
      url: `/branches/${id}`,
      method: 'delete',
      timeout: 300000,
    })
  }
