import request from '../utils/request'


export function getDoctor(params) {
    return request({
        url: '/bonus_service/get_doctor',
        method: 'get',
        params,
        timeout: 300000
    })
}

export function getPartnerClinic(params) {
    return request({
        url: '/bonus_service/get_partner_clinic',
        method: 'get',
        params,
        timeout: 300000
    })
}

export function getPartnerDoctor(params) {
    return request({
        url: '/bonus_service/get_partner_doctor',
        method: 'get',
        params,
        timeout: 300000
    })
}

export function store(data) {
    return request({
        url: '/bonus_service',
        method: 'post',
        data,
        timeout: 300000
    })
}
