import request from './../utils/request';

export function index(params) {
  return request({
    url: '/categories',
    method: 'GET',
    params,
    timeout: 300000
  });
}

export function show(id) {
  return request({
    url: '/categories/' + id,
    method: 'GET',
    timeout: 300000
  });
}

export function store(data) {
  return request({
    url: '/categories',
    method: 'POST',
    data,
    timeout: 300000
  });
}

export function update(data) {
  return request({
    url: '/categories/' + data.id,
    method: 'PUT',
    data,
    timeout: 300000
  });
}

export function destroy(id) {
  return request({
    url: '/categories/' + id,
    method: 'DELETE',
    timeout: 300000
  });
}

export function inventory(params) {
  return request({
    url: '/categories/inventory',
    method: 'GET',
    params,
    timeout: 300000
  });
}